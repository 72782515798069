var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.step,staticClass:"register-form"},[_c('form',{ref:"registerForm",attrs:{"action":"javascript:void(0)"}},[(_vm.step === 1)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Ваш email")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Отправим на него письмо с кодом подтверждения ")]),_c('TextField',{staticClass:"mb-8",attrs:{"type":'email',"label":'Email*',"rules":[
          _vm.Rules.isRequired(
            _vm.email,
            'Для регистрации нужно заполнить Email адрес'
          ),
          _vm.Rules.isCorrectEmail(_vm.email, 'Некорректный email'),
          !_vm.emailAlreadyExists || _vm.$route.name === 'TgRegister'
            ? ''
            : 'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>' ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('Checkbox',{staticClass:"mb-4",attrs:{"label":"Я даю свое согласие на <a href='#openPolicy'>обработку персональных данных</a>*","rules":[
          _vm.Rules.isRequired(
            _vm.personalData,
            'Для регистрации нужно согласие на обработку персональных данных'
          ) ]},model:{value:(_vm.personalData),callback:function ($$v) {_vm.personalData=$$v},expression:"personalData"}}),_c('Checkbox',{staticClass:"mb-4",attrs:{"label":"Я подтверждаю, что являюсь сотрудником здравоохранения*","type":'checkbox',"rules":[
          _vm.Rules.isRequired(
            _vm.iAmMedic,
            'Для регистрации нужно подтверждение, что вы работник сферы здравоохранения'
          ) ]},model:{value:(_vm.iAmMedic),callback:function ($$v) {_vm.iAmMedic=$$v},expression:"iAmMedic"}}),_c('Checkbox',{attrs:{"label":"Я хочу получать рассылку с информацией и полезными материалами на Email","type":'checkbox'},model:{value:(_vm.subscribeEmail),callback:function ($$v) {_vm.subscribeEmail=$$v},expression:"subscribeEmail"}}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"register-step__button button_pink button",class:{ button_disabled: _vm.emailSended === 'wait' },on:{"click":_vm.emailVerify}},[_vm._v(" Продолжить ")]),(_vm.emailSended === 'wait')?_c('Preloader',{staticClass:"mt-10 ml-4"}):_vm._e()],1)],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Ваш email")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Отправили письмо с кодом на "+_vm._s(_vm.email)+". "),_c('span',{staticClass:"register-step__description_pink",on:{"click":_vm.changeEmail}},[_vm._v("Изменить email?")])]),_c('TextField',{attrs:{"type":'tel',"maskString":'####',"label":'Код из письма*',"readonly":_vm.codeConfirmed,"rules":[
          _vm.Rules.isRequired(_vm.letterCode, 'Неправильный код'),
          _vm.codeConfirmed !== false || 'Неправильный код' ],"successState":_vm.codeConfirmed},model:{value:(_vm.letterCode),callback:function ($$v) {_vm.letterCode=$$v},expression:"letterCode"}},[(!_vm.codeConfirmed)?_c('GetCode',{staticClass:"ml-4 ml-xs-0 mt-xs-4",attrs:{"refresh":_vm.checkEmail,"args":[_vm.email]}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"register-step__button button_pink button",class:{ button_disabled: !_vm.codeConfirmed },on:{"click":function($event){_vm.validate(Object.keys(_vm.snaplogicData).length ? 5 : 3)}}},[_c('span',[_vm._v("Продолжить")])]),(_vm.codeConfirmed === null && _vm.letterCode.length > 3)?_c('Preloader',{staticClass:"mt-10 ml-4"}):_vm._e()],1)],1):_vm._e(),(_vm.step === 3)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Личные данные")]),_c('TextField',{staticClass:"mb-8 mt-8",attrs:{"type":'text',"label":'Фамилия*',"rules":[_vm.Rules.isRequired(_vm.lastName, 'Укажите вашу фамилию')]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('TextField',{staticClass:"mb-8",attrs:{"type":'text',"label":'Имя*',"rules":[_vm.Rules.isRequired(_vm.name, 'Укажите ваше имя')]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('TextField',{staticClass:"mb-8",attrs:{"type":'text',"label":'Отчество*',"rules":[_vm.Rules.isRequired(_vm.secondName, 'Укажите ваше отчество')]},model:{value:(_vm.secondName),callback:function ($$v) {_vm.secondName=$$v},expression:"secondName"}}),_c('Select',{attrs:{"items":_vm.specialties,"searchable":true,"label":'Специальность*',"placeholder":'Выбрать специальность из списка',"rules":[
          _vm.Rules.isRequired(_vm.speciality.length > 0, 'Выберите специальность') ]},model:{value:(_vm.speciality),callback:function ($$v) {_vm.speciality=$$v},expression:"speciality"}}),_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){return _vm.validate(4)}}},[_vm._v(" Продолжить ")])],1):_vm._e(),(_vm.step === 4)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Основное место работы")]),_c('TextField',{staticClass:"mb-8 mt-8 mb-xs-4",attrs:{"type":'text',"label":'Учреждение*',"rules":[_vm.Rules.isRequired(_vm.institution, 'Укажите учреждение')]},model:{value:(_vm.institution),callback:function ($$v) {_vm.institution=$$v},expression:"institution"}}),_c('TextField',{staticClass:"mb-8 mb-xs-4",attrs:{"type":"address","label":'Адрес*',"rules":[
          _vm.Rules.isRequired(
            _vm.address,
            'Выберите адрес учреждения из выпадающего списка'
          ) ]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('TextField',{staticClass:"mb-8 mb-xs-4",attrs:{"type":'text',"label":'Отделение',"disabled":_vm.isNotDepartment === true,"rules":[
          _vm.Rules.isRequired(
            _vm.department,
            'Укажите отделение или нажмите «Нет отделения»'
          ) ]},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}},[_c('Checkbox',{staticClass:"ml-5 ml-xs-0 mt-xs-4",attrs:{"label":"Нет отделения","type":'checkbox'},model:{value:(_vm.isNotDepartment),callback:function ($$v) {_vm.isNotDepartment=$$v},expression:"isNotDepartment"}})],1),_c('div',{staticClass:"buttons-container"},[_c('div',{staticClass:"register-step__button button_empty-pink back button",on:{"click":function($event){_vm.step = 3}}},[_vm._v(" Назад ")]),_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){return _vm.validate(5)}}},[_vm._v(" Продолжить ")])])],1):_vm._e(),(_vm.step === 5)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v(" "+_vm._s(Object.keys(_vm.snaplogicData).length ? ("Здравствуйте, " + _vm.name) : "Номер телефона")+" ")]),_vm._m(0),_c('TextField',{staticClass:"mb-8 mb-xs-4",attrs:{"type":'tel',"maskString":'+7 (###) ###-##-##',"placeholder":'+7(---) --- -- --',"label":'Номер телефона',"disabled":_vm.noPhone === true,"rules":[
          _vm.Rules.isRequired(_vm.phoneNumber, 'Некорректный номер'),
          _vm.Rules.isCorrectPhone(_vm.phoneNumber, 'Некорректный номер'),
          !_vm.phoneAlreadyExists ||
            'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>' ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}},[_c('Checkbox',{staticClass:"ml-5 ml-xs-0 mt-xs-4",attrs:{"label":"Я отказываюсь использовать номер","type":'checkbox'},model:{value:(_vm.noPhone),callback:function ($$v) {_vm.noPhone=$$v},expression:"noPhone"}})],1),_c('Checkbox',{staticClass:"mb-8",attrs:{"label":"Я хочу получать рассылку с информацией и полезными материалами по СМС","type":'checkbox',"disabled":_vm.noPhone === true},model:{value:(_vm.subscribePhone),callback:function ($$v) {_vm.subscribePhone=$$v},expression:"subscribePhone"}}),_c('div',{staticClass:"buttons-container"},[(!Object.keys(_vm.snaplogicData).length)?_c('div',{staticClass:"register-step__button button_empty-pink back button",on:{"click":function($event){_vm.step--}}},[_vm._v(" Назад ")]):_vm._e(),_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){_vm.noPhone ? _vm.validate(7) : _vm.validate(6, _vm.checkPhone, _vm.phoneNumber)}}},[_vm._v(" Продолжить ")])])],1):_vm._e(),(_vm.step === 6)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Подтверждение номера телефона")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Отправили смс с кодом на номер +7 "+_vm._s(_vm.phoneNumber)+". "),_c('span',{staticClass:"register-step__description_pink",on:{"click":_vm.changePhone}},[_vm._v("Изменить номер?")])]),_c('TextField',{attrs:{"type":'tel',"maskString":'####',"readonly":_vm.smsConfirmed,"label":'Код из смс*',"rules":[
          _vm.Rules.isRequired(_vm.smsCode, 'Неправильный код'),
          _vm.smsConfirmed !== false || 'Неправильный код' ],"successState":_vm.smsConfirmed},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}},[(!_vm.smsConfirmed)?_c('GetCode',{staticClass:"ml-4 ml-xs-0 mt-xs-4",attrs:{"refresh":_vm.checkPhoneResend}}):_vm._e()],1),_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){return _vm.validate(7)}}},[_vm._v(" Продолжить ")])],1):_vm._e(),(_vm.step === 7)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Придумайте пароль")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Нужен для входа на портал через email ")]),_c('TextField',{staticClass:"mb-8 pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Введите пароль*',"rules":[
          _vm.Rules.isRequired(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.onlyLatin(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.minLength(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.digitsReq(_vm.password, 'Пароль не соответствует требованиям'),
          !_vm.inputError || _vm.inputError ],"successState":(_vm.Rules.isRequired(_vm.password, false) &&
            _vm.Rules.onlyLatin(_vm.password, false) &&
            _vm.Rules.minLength(_vm.password, false) &&
            _vm.Rules.digitsReq(_vm.password, false) &&
            !_vm.inputError) ||
          false},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",class:{ 'show-icon_active': _vm.showPass },on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"currentColor","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"currentColor","stroke-linecap":"square"}})])])]},proxy:true},{key:"alternate-error",fn:function(){return [_c('div',{staticClass:"pass-requirements"},[_c('div',{staticClass:"req__title"},[_vm._v("Требования к паролю")]),_c('ul',{staticClass:"req__list mt-2"},[_c('li',{staticClass:"req__point mb-2",class:[
                  _vm.Rules.onlyLatin(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Только латинские буквы ")]),_c('li',{staticClass:"req__point",class:[
                  _vm.Rules.minLength(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум 8 символов ")]),_c('li',{staticClass:"req__point",class:[
                  _vm.Rules.digitsReq(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум одна цифра ")])])])]},proxy:true}],null,false,2140949252),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('TextField',{staticClass:"pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Повторите пароль*',"rules":[
          _vm.Rules.isRequired(_vm.passwordRepeat, 'Пароли не совпадают'),
          _vm.passwordRepeat === _vm.password || 'Пароли не совпадают' ],"successState":_vm.passwordRepeat === _vm.password},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",class:{ 'show-icon_active': _vm.showPass },on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"currentColor","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"currentColor","stroke-linecap":"square"}})])])]},proxy:true}],null,false,3111249292),model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('div',{staticClass:"buttons-container"},[_c('div',{staticClass:"register-step__button button_empty-pink back button",on:{"click":function($event){_vm.step = 5}}},[_vm._v(" Назад ")]),_c('div',{staticClass:"register-step__button button_pink big-button button",on:{"click":function($event){return _vm.validate(8, _vm.Register)}}},[_vm._v(" Зарегистрироваться ")])])],1):_vm._e(),(_vm.$route.name !== 'TgRegister' && _vm.step === 8 && false)?_c('PersonalityTest',{attrs:{"showContinue":true},on:{"save":_vm.save,"continueEv":_vm.continueEv}}):_vm._e(),(
        (_vm.$route.name === 'TgRegister' && _vm.step === 8) ||
        (_vm.$route.name !== 'TgRegister' && _vm.step === 8)
      )?_c('div',{staticClass:"register-step"},[(_vm.$route.name === 'TgRegister')?_c('div',[_c('div',{staticClass:"register-step__title"},[_vm._v("Спасибо!")]),_vm._m(1)]):_c('div',[_c('div',{staticClass:"register-step__title"},[_vm._v("Регистрация завершена")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" "+_vm._s(_vm.name)+", спасибо, что зарегистрировались на портале AZ Мост! "),_c('br'),_c('br'),_vm._v(" Отправили вашу учётную запись на верификацию, подтверждаем ваш статус специалиста здравоохранения. Когда всё проверим — сообщим вам по email или позвоним. Откроем полный доступ к порталу. "),_c('br'),_c('br'),_vm._v(" Сейчас у вас есть неполный доступ — можете перейти на главную и познакомиться с некоторыми функциями "+_vm._s(_vm.speciality[0] && _vm.tgSpeciality.includes(_vm.speciality[0]) ? ", а также перейти в наш Telegram-канал, созданный для специалистов здравоохранения." : ".")+" ")])]),(_vm.$route.name === 'TgRegister')?_c('div',{staticClass:"register-step__button button_pink button px-4",on:{"click":function($event){return _vm.goToTg()}}},[_vm._v(" Перейти в Telegram ")]):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"register-step__button button_pink button px-4 mr-4",on:{"click":function($event){return _vm.returnToSite()}}},[_vm._v(" На главную ")]),(
            _vm.speciality[0] &&
            _vm.tgSpeciality.includes(_vm.speciality[0].toLowerCase()) &&
            false
          )?_c('div',{staticClass:"register-step__button button_empty-pink button px-4",on:{"click":function($event){return _vm.goToTg()}}},[_vm._v(" Перейти в Telegram ")]):_vm._e()])]):_vm._e()],1),_c('RegisterFooter')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-step__description"},[_vm._v(" Укажите телефон, чтобы быстро входить на портал по смс-коду. "),_c('br'),_vm._v(" Можно добавить сейчас или позже в личном кабинете. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-step__description"},[_vm._v(" Регистрация завершена. "),_c('br'),_c('br'),_vm._v(" Подтверждаем ваш статус специалиста здравоохранения. После проверки откроем вам полный доступ. Сейчас у вас временный - можете перейти на главную страницу и познакомиться с некоторыми функциями сайта, а также перейти в наш Telegram - канал ")])}]

export { render, staticRenderFns }